export const blockInvalidChar = (e) => {
    if (['e', 'E', '+','-','.'].includes(e.key)) {
      e.preventDefault();
    }
  };
  export const blockInvalidCharater = (e) => {
    if (!/^\d+$/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  // export const allowOnlyAlphabets = (e) => {
  //   const alphabetRegex = /^[a-zA-Z]$/;
  //   if (!alphabetRegex.test(e.key)) {
  //     e.preventDefault();
  //   }
  // };
  

  export const allowOnlyAlphabets = (e) => {
    const alphabetRegex = /^[a-zA-Z\s]$/;
    const allowedKeys = ['Backspace'];
  
    if (!alphabetRegex.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  