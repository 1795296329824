import { useState, useEffect } from "react";
// @mui
import { MenuItem, Stack } from "@mui/material";
// locales
import { useLocales } from "../../../locales";
import { useAuthContext } from "../../../auth/useAuthContext";
// components
import Image from "../../../components/image";
import MenuPopover from "../../../components/menu-popover";
import Iconify from "../../../components/iconify";
import { IconButtonAnimate } from "../../../components/animate";
import { setLevel } from "../../../auth/utils";
import { getCategories } from "src/redux/slices/category";
import { dispatch } from "src/redux/store";

// ----------------------------------------------------------------------

export default function LevelPopover({ handleLevelChange }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const { user } = useAuthContext();

  const [openPopover, setOpenPopover] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(null);

  useEffect(() => {
    if (user && user.venue.levels.length > 0) {
      setLevel(user.venue.levels[0]);
      setCurrentLevel(user.venue.levels[0]);
      localStorage.setItem("currentLevel", currentLevel);
    }
  }, []);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  // const handleChangeLevel = (newLevel) => {
  //   setLevel(newLevel);
  //   setCurrentLevel(newLevel);Z
  //   handleClosePopover();
  // };

  const handleChangeLevel = async (newLevel) => {
    setLevel(newLevel);
    setCurrentLevel(newLevel);
    handleClosePopover();

    try {
      await dispatch(getCategories(newLevel.id, user.venue.id));
      handleLevelChange();
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle error as needed
    }
  };

  return (
    <>
    {user?.venue?.levels?.length>1 &&(
<>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: "action.selected",
          }),
        }}>
        <Iconify icon="eva:pin-outline" />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 180 }}>
        <Stack spacing={0.75}>
          {user !== undefined &&
            user.venue.levels.map((option) => (
              <MenuItem
                key={option.id}
                selected={option.id === currentLevel?.id}
                onClick={() => handleChangeLevel(option)}>
                {option.name}
              </MenuItem>
            ))}
        </Stack>
      </MenuPopover>
</>
    )}
    </>
  );
}
