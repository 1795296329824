import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  micsExpense: [],
};

const micsExpenseSlice = createSlice({
  name: "micsExpense",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getMicsExpenseSuccess(state, action) {
      state.isLoading = false;
      state.micsExpense = action.payload;
    },
  },
});

export default micsExpenseSlice.reducer;

export function getMicsExpense(levelId, venueId, token) {
  return async (dispatch) => {
    dispatch(micsExpenseSlice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/miscExpense?levelId=${levelId}&venueId=${venueId}&isDeleted=false&limit=9999999`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(
        micsExpenseSlice.actions.getMicsExpenseSuccess(response.data?.results)
      );
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(micsExpenseSlice.actions.hasError(error));
      throw error
    }
  };
}

export function addMicsExpense(levelId, venueId, venueUserId, token, data) {
  return async (dispatch) => {
    dispatch(micsExpenseSlice.actions.startLoading());
    try {
      const response = await axios.post(
        `${backendUrl}/miscExpense`,
        {
          levelId: levelId,
          venueId: venueId,
          venueUserId: venueUserId,
          amount: data.amount,
          description: data?.description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(micsExpenseSlice.actions.getMicsExpenseSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error("error", error);
      dispatch(micsExpenseSlice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateMicsExpense(id, data, token) {
  return async (dispatch) => {
    dispatch(micsExpenseSlice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${backendUrl}/miscExpense/${id}`,
        {
          amount: data.amount,
          description: data?.description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(micsExpenseSlice.actions.getMicsExpenseSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(micsExpenseSlice.actions.hasError(error));
      throw error
    }
  };
}

export function deleteMicsExpense(id, token, userid) {
  return async (dispatch) => {
    dispatch(micsExpenseSlice.actions.startLoading());
    try {
      const response = await axios.delete(`${backendUrl}/miscExpense/${id}?deletedById=${userid}&deletedBy=${'venueUser'}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(micsExpenseSlice.actions.hasError(error));
      throw error
    }
  };
}
