// @mui
import { Stack, Box, FormControlLabel, Switch } from '@mui/material';
// config
import { NAV } from '../../../config-global';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';

export default function NavMini({ handleVenueOnlineStatusChange, isVenueOnline }) {
  const { user, logout } = useAuthContext();

  if (navConfig.length) {
    navConfig[0].items.map((group, index) => (
      console.log(group)
    ))
    const result = navConfig[0].items.filter((item, index) => {
      if ((item.title === 'dashboard' && user.isSuperAdmin !== true && user.permissions?.canViewDashboard !== true)) {
        return false;
      } else {
        return true;
      }
      // return item.shouldShow; // Replace with your condition
    })
    navConfig[0].items = result;

  }

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 30,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        spacing={2}
        sx={{
          top: 15,
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <center><img width={50} src="https://www.egora.pk/assets/img/favicon-portal.png" alt="logo" /></center>
        {(user?.venue?.configurations?.isPickUp || user?.venue?.configurations?.isDineIn) &&
          <FormControlLabel
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginLeft: "0",
              marginRight: "0",
              color: "#637381",
              fontSize: '10px',
            }}
            label={isVenueOnline ? "Online" : "Offline"}
            labelPlacement="start"
            control={
              <Switch
                size="small"
                name="online"
                checked={isVenueOnline}
                onChange={(e) =>
                  handleVenueOnlineStatusChange(e)
                }
              />
            }
          />
        }
        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
}
