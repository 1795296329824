import PropTypes from 'prop-types';
import { Box, TablePagination } from '@mui/material';
import { useLocation } from 'react-router-dom';

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
};

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions,
  sx,
  ...other
}) {
  const location = useLocation();
  const hideRowsPerPage = location.pathname === '/dashboard/item/new';

  if (location.pathname === '/dashboard/ledger') {
    rowsPerPageOptions = [50, 100, 200];
  } else {
    rowsPerPageOptions = rowsPerPageOptions || [5, 10, 25];
  }

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={hideRowsPerPage ? [] : rowsPerPageOptions}
        component="div"
        SelectProps={{ style: { display: hideRowsPerPage ? 'none' : 'inline-flex' } }}
        {...other}
      />
    </Box>
  );
}

