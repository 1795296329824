import { add, subDays } from 'date-fns';
//
import _mock from '../_mock';
import { randomInArray, randomNumberRange } from '../utils';

// ----------------------------------------------------------------------

export const _venues = [...Array(3)].map((_, itemIndex) => ({
id: _mock.id(itemIndex),
avatarUrl: _mock.image.avatar(itemIndex),
name: randomInArray(['Jack n Jill', 'Rock n Roll', 'Summer Punch']),
description: randomInArray(['Add-On: Pepsi, Half Ice', 'Add-On: Fanta, Full Ice', 'Add-On: Coke, Half Ice']),
quantity: 1,
price: _mock.number.price(itemIndex),
total: _mock.number.price(itemIndex),
service: randomInArray([
    'full stack development',
    'backend development',
    'ui design',
    'ui/ux design',
    'front end development',
]),
}));