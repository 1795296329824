import { useLocation } from 'react-router-dom';

export default function useActiveLink(paths, deep = true) {
  const { pathname } = useLocation();

  const isPathActive = (path) => {
    if (typeof path === 'function') {
      path = path(':id');
    }
    const pathPattern = path.replace(/:[^/]+/g, '[^/]+');
    const regex = new RegExp(`^${pathPattern}${deep ? '' : '$'}`);
    return regex.test(pathname);
  };
  const active = Array.isArray(paths) ? paths.some(isPathActive) : isPathActive(paths);
  const isExternalLink = Array.isArray(paths)
    ? paths.some((path) => typeof path === 'string' && path.includes('http'))
    : typeof paths === 'string' && paths.includes('http');
  return {
    active,
    isExternalLink,
  };
}
