// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("customer"),
  file: icon("ic_file"),
  lock: icon("settings"),
  tables: icon("tables"),
  blank: icon("ic_blank"),
  kanban: icon("terminal"),
  orders: icon("orders"),
  banking: icon("ledger"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("menu"),
  staff: icon("staff"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("report"),
  dashboard: icon("dashboard"),
  complaintList: icon("ic_complaint_list"),
  barList: icon("ic_bar_list"),
  campaignList: icon("ic_campaign_list"),
  tableChart: icon("ic_table_chart"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "Welcome Back!",
    items: [
      {
        title: "dashboard",
        path: PATH_DASHBOARD.general.app,
        pathList: [PATH_DASHBOARD.general.app],
        icon: ICONS.dashboard,
      },
      // POS
      {
        title: "terminal",
        path: PATH_DASHBOARD.pos.list,
        pathList: [
          PATH_DASHBOARD.pos.list,
          PATH_DASHBOARD.tables.list,
          PATH_DASHBOARD.orders.list,
          PATH_DASHBOARD.kitchen.list,
          PATH_DASHBOARD.reservation.calendar
        ],
        icon: ICONS.kanban,
      },
      // STAFF
      {
        title: "staff",
        path: PATH_DASHBOARD.user.account,
        pathList: [
          PATH_DASHBOARD.user.account,
          PATH_DASHBOARD.user.new,
          PATH_DASHBOARD.user.list,
          PATH_DASHBOARD.user.profile,
          PATH_DASHBOARD.desigination.new,
          PATH_DASHBOARD.user.edit,
          PATH_DASHBOARD.user.replicate,
        ],
        icon: ICONS.staff,
      },
      // MENU
      {
        title: "menu",
        path: PATH_DASHBOARD.menu.display,
        pathList: [
          PATH_DASHBOARD.menu.display,
          PATH_DASHBOARD.ingredient.new,
          PATH_DASHBOARD.category.new,
          PATH_DASHBOARD.item.new,
          PATH_DASHBOARD.choice.new,

        ],
        icon: ICONS.menuItem,
      },
      // INVENTORY
      {
        title: "inventory",
        path: PATH_DASHBOARD.inventory.display,
        pathList: [
          PATH_DASHBOARD.inventory.display,
          PATH_DASHBOARD.inventory.new,
          PATH_DASHBOARD.category.edit,
          PATH_DASHBOARD.category.replicate,
        ],
        icon: ICONS.menuItem,
      },
      // TABLES
      {
        title: "tables",
        path: PATH_DASHBOARD.table.account,
        pathList: [
          PATH_DASHBOARD.table.account,
          PATH_DASHBOARD.area.new,
          PATH_DASHBOARD.table.new,
          PATH_DASHBOARD.table.edit,
          PATH_DASHBOARD.area.edit,
        ],
        icon: ICONS.tables,
      },
      // RESERVATION
      /* {
        title: 'reservations',
        path: PATH_DASHBOARD.reservation.list,
        icon: ICONS.kanban
      }, */
      // ORDERS
      {
        title: "orders",
        path: PATH_DASHBOARD.order.list,
        pathList: [PATH_DASHBOARD.order.list],
        icon: ICONS.orders,
      },
      // REPORTS
      {
        title: "report",
        path: PATH_DASHBOARD.general.report,
        pathList: [PATH_DASHBOARD.general.report],
        icon: ICONS.analytics,
      },
      {
        title: "Ledger",
        path: PATH_DASHBOARD.general.ledger,
        pathList: [PATH_DASHBOARD.general.ledger],
        icon: ICONS.banking,
      },
      {
        title: "customers",
        path: PATH_DASHBOARD.customer.list,
        pathList: [PATH_DASHBOARD.customer.list],
        icon: ICONS.user,
      },
      // // NOTIFICATIONS
      // {
      //   title: 'notifications',
      //   path: PATH_DASHBOARD.notification.list,
      //   icon: ICONS.chat
      // },
      // // PAYMENTS
      // {
      //   title: 'payments',
      //   path: PATH_DASHBOARD.bar.list,
      //   icon: ICONS.invoice
      // },
      // PROMOTIONS
      // {
      //   title: 'promotions',
      //   path: PATH_DASHBOARD.user.list,
      //   icon: ICONS.campaignList
      // },
      // Qr Code

      {
        title: "settings",
        path: PATH_DASHBOARD.settings.new,
        pathList: [PATH_DASHBOARD.settings.new],
        icon: ICONS.lock,
      },
      // CUSTOMER
      // {
      //   title: 'customers',
      //   path: PATH_DASHBOARD.customer.list,
      //   icon: ICONS.user
      // },
      // BARS
      // {
      //   title: 'Venues',
      //   path: PATH_DASHBOARD.bar.list,
      //   icon: ICONS.barList
      // },
      // ORDERS
      // {
      //   title: 'order',
      //   path: PATH_DASHBOARD.order.list,
      //   icon: ICONS.menuItem
      // },
      // COMPLAINT
      // {
      //   title: 'complaint',
      //   path: PATH_DASHBOARD.complaint.list,
      //   icon: ICONS.complaintList
      // },
      // CAMPAIGN
      // {
      //   title: 'campaign',
      //   path: PATH_DASHBOARD.campaign.list,
      //   icon: ICONS.campaignList
      // },
      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.list,
      //   icon: ICONS.invoice,
      // },
      // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'stats', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
  //     {
  //       title: 'ecommerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //     {
  //       title: 'File manager',
  //       path: PATH_DASHBOARD.fileManager,
  //       icon: ICONS.folder,
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level',
  //       path: '#/dashboard/menu_level',
  //       icon: ICONS.menuItem,
  //       children: [
  //         {
  //           title: 'menu_level_2a',
  //           path: '#/dashboard/menu_level/menu_level_2a',
  //         },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default navConfig;
