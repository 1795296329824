import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  Radio,
  FormControlLabel
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// components
import Label from "../../../../components/label";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
import ConfirmDialog from "../../../../components/confirm-dialog";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

StockTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  handleRowClick:PropTypes.func,
  defaultVariant:PropTypes.array
};

export default function StockTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  handleRowClick,
  defaultVariant
}) {
  const { id, quantity, price, name, ingredient } = row;

  const serverUrl = `${backendUrl}/images/`;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const navigate = useNavigate();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDeleteRow = (id) => {
    onDeleteRow(id);
  };

  const handleOpenAccount = (event) => {
    navigate(PATH_DASHBOARD.item.edit(paramCase(id)));
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
          
            <Typography variant="subtitle2" noWrap onClick={handleOpenAccount}>
              {ingredient? ingredient : name ? name : ""}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{price}</TableCell>

        <TableCell align="left">{quantity}</TableCell>

        <TableCell align="right">
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={() => {
              handleDeleteRow(id);
            }}
          >
            <Iconify icon="eva:trash-2-outline" />
          </IconButton>
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={() => {
              onEditRow(id);
            }}
          >
            <Iconify icon="eva:edit-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
