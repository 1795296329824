// ----------------------------------------------------------------------

export const role = [
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
  'staff',
  'manager',
];
