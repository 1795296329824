// import React, { useContext } from 'react';
// import { useAuthContext } from 'src/auth/useAuthContext';
// import { Navigate } from 'react-router-dom';
// const PrivateRoute = ({ component: Component, permission, ...rest }) => {
//   const { user, logout } = useAuthContext();
//   if (!user.permissions[permission]) {
//     return <Navigate to='/404' />;
//   }
//   else {
//     return (
//       <Component />
//     );
//   }
// };

// export default PrivateRoute;

import React from 'react';
import { useAuthContext } from 'src/auth/useAuthContext';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, permission, superAdminRoute, ...rest }) => {
  const { user } = useAuthContext();
  if (superAdminRoute && !user?.isSuperAdmin) {
    return <Navigate to='/404' />
  }
  if (permission && !user?.permissions[permission]) {
    return <Navigate to='/404' />
  }
  return <Component {...rest} />
};

export default PrivateRoute;
