import PropTypes from "prop-types";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Typography,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  InputLabel,
  FormControl,
  Button,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  useTable,
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
} from "../../../components/table";
// utils
import { fData } from "../../../utils/formatNumber";
import axios from "../../../utils/axios";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// redux
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "../../../redux/store";
import { getIngredients } from "src/redux/slices/ingredient";
import { blockInvalidChar } from "src/utils/blockInvalidChar";

// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// components
import { useSnackbar } from "../../../components/snackbar";
import Scrollbar from "../../../components/scrollbar";
import FormProvider, {
  RHFTextField,
  RHFUploadAvatar,
} from "../../../components/hook-form";

import StockTableRow from "../menu/list/StockTableRow";

import { getStocks } from "src/redux/slices/stock";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "left" },
  { id: "price", label: "Price", align: "left" },
  { id: "description", label: "Quantity", align: "left" },
  { id: "" },
];

StockNewEditForm.propTypes = {
  // isEdit: PropTypes.bool,
  currentItem: PropTypes.object,
};

export default function StockNewEditForm({ currentItem }) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    setSelected,
    onSelectRow,
  } = useTable();

  const navigate = useNavigate();

  const serverUrl = `${backendUrl}/images/`;

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { user } = useAuthContext();

  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [editVariant, setEditVariant] = useState(null);
  const [defaultVariant, setDefaultVariant] = useState(null);
  const [errorForVariant, setErrorFoVariant] = useState("");
  const { ingredients, isLoading } = useSelector((state) => state.ingredient);
  const [inputError, setInputError] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIngredientId, setSelectedIngredientId] = useState(null);

  const { stocks } = useSelector((state) => state?.stockSlice);

  const [id, setid] = useState(null);

  const [stockData, setStockData] = useState([]);
  const handleRowClick = (id) => {
    setDefaultVariant(id);
  };
  const clearItem = () => {
    setItemName("");
    setItemPrice("");
    setItemDescription("");
    setItemQuantity("");
    setErrorFoVariant("");
    setSelectedUnit("");
  };

  const NewUserSchema = Yup.object().shape({
    amount: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      price: currentItem?.price || "",
      quantity: currentItem?.quantity || "",
      ingredientId: currentItem?.ingredientId || "",
      hasVariant: currentItem?.hasVariant || false,
      stockIngredients: currentItem?.stockIngredients || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItem]
  );

  useEffect(() => {
    const level = JSON.parse(localStorage.getItem("levelSelected"));
    dispatch(getStocks(user?.venue.id, level.id));
  }, [dispatch, user?.venue?.id]);

  console.log("user?.venue?.id", user?.venue?.id);

  useEffect(() => {
    const level = JSON.parse(localStorage.getItem("levelSelected"));
    const getToken = localStorage.getItem("accessToken");
    dispatch(getIngredients(level.id, user.venue.id, getToken));
  }, [dispatch, user.venue.id]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  console.log("ingredients", ingredients);

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const handleChangeAmount = (e) => {
    let value = e.target.value;
    if (value === "0") {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  const denseHeight = dense ? 52 : 72;

  const onSubmit = async (data) => {
    console.log("Form submitted with data:", data);

    if (defaultValues.stockIngredients.length === 0) {
      setErrorFoVariant("Please add at least one ingredient");
      return;
    }

    const idToSubmit = id;

    try {
      const response = await axios.patch(
        `${backendUrl}/stock/updateStockState/${idToSubmit}`,
        {
          ...data,
        }
      );

      if (response.status === 200) {
        enqueueSnackbar("Stock updated successfully!", { variant: "success" });
        navigate(PATH_DASHBOARD.inventory.display);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update stock.", { variant: "error" });
    }
  };

  const handleAddIngredients = async () => {
    if (itemName && itemPrice && itemQuantity) {
      const ingredientId = selectedIngredientId;
      const level = JSON.parse(localStorage.getItem("levelSelected"));

      const dataToSend = {
        levelId: level?.id,
        venueId: user?.venue?.id,
        venueUserId: user.id,
        quantityWithPrice: {
          price: Number(itemPrice),
          quantity: Number(itemQuantity),
        },
      };

      const params = {
        quantityWithPriceId: isEdit
          ? ingredients?.quantityWithPriceId
          : undefined,
        isEdit,
      };

      try {
        const res = await axios.patch(
          `${backendUrl}/ingredient/addIngredientInStock/${ingredientId}`,
          dataToSend,
          { params }
        );

        if (res.data && res.data.id) {
          setid(res.data.id);
        }

        const newVariant = {
          id: defaultValues.stockIngredients.length + 1,
          name: itemName,
          price: Number(itemPrice),
          quantity: Number(itemQuantity),
          _id: isEdit ? id : undefined,
        };

        if (isEdit) {
          defaultValues.stockIngredients[editVariant] = newVariant;
        } else {
          defaultValues.stockIngredients = [
            ...defaultValues.stockIngredients,
            newVariant,
          ];
        }

        setValue("stockIngredients", defaultValues.stockIngredients);
        setIsEdit(false);

        enqueueSnackbar(
          isEdit
            ? "Ingredient successfully updated in stock"
            : "Ingredient successfully added to stock",
          {
            variant: "success",
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to add/update ingredient in stock", {
          variant: "error",
        });
      }
      clearItem();
    }
  };
  const handleIngredientChange = (event) => {
    const selectedIngredient = ingredients.find(
      (ingredient) => ingredient.name === event.target.value
    );
    setItemName(event.target.value);
    setSelectedUnit(selectedIngredient ? selectedIngredient.unit : "");

    setSelectedIngredientId(selectedIngredient ? selectedIngredient.id : null);
  };

  const handleDeleteRow = (id) => {
    const remaning = defaultValues.stockIngredients.filter(
      (row) => id != row.id
    );
    defaultValues.stockIngredients = remaning;
    if (defaultValues.stockIngredients.length == 0) {
      setDefaultVariant(null);
    } else if (defaultVariant == id) {
      setDefaultVariant(defaultValues.stockIngredients[0].id);
    }
    setSelected([]);
  };

  const handleEditRow = (id) => {
    const index = defaultValues.stockIngredients.findIndex(
      (item) => item.id === id
    );
    setEditVariant(index);
    setItemName(defaultValues.stockIngredients[index].name);
    setItemDescription(defaultValues.stockIngredients[index].description);
    setItemQuantity(defaultValues.stockIngredients[index].quantity);
    setItemPrice(defaultValues.stockIngredients[index].price);
    setid(defaultValues.stockIngredients[index]._id); 
    setIsEdit(true);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Card>
            <Box
              sx={{ pt: 3, pl: 3, pr: 3, mb: 2 }}
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <Controller
                  name="amount"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Logistic cost"
                      type="number"
                      onKeyDown={blockInvalidChar}
                      error={!!error}
                      helperText={error?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rs.</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Date"
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                      maxDate={new Date()}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box></Box>

            <Divider />

            <>
              <Box>
                <Scrollbar>
                  <Table
                    size={dense ? "small" : "medium"}
                    sx={{ minWidth: 800 }}
                  >
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={defaultValues?.stockIngredients.length}
                    />

                    <TableBody>
                      {defaultValues?.stockIngredients.map((row) => (
                        <StockTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                          handleRowClick={() => handleRowClick(row.id)}
                          defaultVariant={defaultVariant}
                        />
                      ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          defaultValues?.stockIngredients.length
                        )}
                      />

                      <TableRow>
                        <TableCell
                          colSpan={12}
                          sx={{ textAlign: "center" }}
                        ></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={12} sx={{ p: 0 }} />
                      </TableRow>
                    </TableBody>
                  </Table>
                </Scrollbar>
              </Box>

              <Box
                rowGap={3}
                columnGap={2}
                sx={{
                  pl: 3,
                  pr: 3,
                  pb: 3,
                }}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "1fr 1fr 1fr 7%",
                }}
              >
                <FormControl
                  fullWidth
                  error={!!errors.ingredientId}
                  disabled={isLoading}
                >
                  <InputLabel id="ingredientId" name="ingredientId">
                    Ingredient*
                  </InputLabel>
                  <Controller
                    name="ingredientId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        labelId="Ingredient"
                        label="ingredientId*"
                        value={itemName}
                        onChange={(e) => {
                          handleIngredientChange(e);
                          field.onChange(e);
                        }}
                      >
                        {ingredients.map((ingredient) => (
                          <MenuItem key={ingredient.id} value={ingredient.name}>
                            {ingredient.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />

                  {errors.ingredientId && (
                    <Typography
                      color="error"
                      sx={{
                        fontSize: "12px",
                        margin: "8px 14px 0px",
                      }}
                    >
                      {errors.ingredientId.message}
                    </Typography>
                  )}
                </FormControl>

                <TextField
                  sx={{ mb: 2 }}
                  name="price"
                  label="Price"
                  type="number"
                  value={itemPrice}
                  onKeyDown={blockInvalidChar}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value)) {
                      setItemPrice(e.target.value);
                    }
                  }}
                  error={inputError}
                  helperText={
                    inputError ? `Amount should be greater than 0.` : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  name="variantQuantity"
                  label="Quantity"
                  value={itemQuantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value)) {
                      setItemQuantity(e.target.value);
                    }
                  }}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />

                <TextField
                  disabled
                  label="Unit"
                  value={selectedUnit}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  sx={{ mb: 2 }}
                />

              
                <Button
                  variant="outlined"
                  onClick={handleAddIngredients}
                  disabled={
                    itemName === "" || itemPrice === "" || itemQuantity === ""
                  }
                >
                  {isEdit ? "Update Ingredients" : "Add Ingredients"}
                </Button>
              </Box>
              <Typography
                sx={{
                  pl: 3,
                  pr: 3,
                  pb: 3,
                }}
                color="red"
              >
                {errorForVariant}
              </Typography>
            </>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pt: 2,
                pr: 2,
                pb: 2,
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {!isEdit ? "Add Stock" : "Save Changes"}
              </LoadingButton>
            </Box>
          </Card>
          <br />
        </Grid>
      </Grid>
    </FormProvider>
  );
}
