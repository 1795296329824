import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  stocks: [],
  stock: null,
};

const slice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET STOCKS
    getStocksSuccess(state, action) {
      state.isLoading = false;
      state.stocks = action.payload;
    },

    // GET STOCK
    getStockSuccess(state, action) {
      state.isLoading = false;
      state.stock = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------

export function getStocks(venueId, levelId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/stock/`, {
        params: { venueId, levelId },
      });

      dispatch(slice.actions.getStocksSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStock(stockId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/stock/${stockId}`);
      dispatch(slice.actions.getStockSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// if (res.status === 201) {
//   const level = JSON.parse(localStorage.getItem("levelSelected"));
//   const venueUserId = user.id;
//   const venueId = user?.venue?.id;

//   const stockId = res.data.id

//   const response = await axios.get(`${backendUrl}/stock/${stockId}`, {
//     params: { venueId, levelId: level.id, venueUserId },
//   });
