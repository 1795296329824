import _mock from '../_mock';
import { randomNumberRange, randomInArray } from '../utils';

// ----------------------------------------------------------------------

export const _userAbout = {
  id: _mock.id(1),
  cover: _mock.image.cover(1),
  role: 'UI Designer',
  follower: randomNumberRange(999, 99999),
  following: randomNumberRange(999, 99999),
  quote:
    'Tart I love sugar plum I love oat cake. Sweet roll caramels I love jujubes. Topping cake wafer..',
  country: _mock.address.country(1),
  email: _mock.email(1),
  company: _mock.company(1),
  school: _mock.company(2),
  socialLinks: {
    facebookLink: `https://www.facebook.com/caitlyn.kerluke`,
    instagramLink: `https://www.instagram.com/caitlyn.kerluke`,
    linkedinLink: `https://www.linkedin.com/in/caitlyn.kerluke`,
    twitterLink: `https://www.twitter.com/caitlyn.kerluke`,
  },
};

export const _userFollowers = [...Array(18)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: _mock.image.avatar(index),
  name: _mock.name.fullName(index),
  country: _mock.address.country(index),
  isFollowed: _mock.boolean(index),
}));

export const _userFriends = [...Array(18)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: _mock.image.avatar(index),
  name: _mock.name.fullName(index),
  role: _mock.role(index),
}));

export const _userGallery = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  title: _mock.text.title(index),
  postAt: _mock.time(index),
  imageUrl: _mock.image.cover(index),
}));

export const _userFeeds = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  author: {
    id: _mock.id(8),
    avatarUrl: _mock.image.avatar(1),
    name: 'Caitlyn Kerluke',
  },
  isLiked: true,
  createdAt: _mock.time(index),
  media: _mock.image.cover(index),
  message: _mock.text.sentence(index),
  personLikes: [...Array(36)].map((__, personIndex) => ({
    name: _mock.name.fullName(personIndex),
    avatarUrl: _mock.image.avatar(personIndex + 2),
  })),
  comments: (index === 2 && []) || [
    {
      id: _mock.id(7),
      author: {
        id: _mock.id(8),
        avatarUrl: _mock.image.avatar(randomInArray([2, 3, 4, 5, 6]) || 2),
        name: _mock.name.fullName(index + 5),
      },
      createdAt: _mock.time(2),
      message: 'Praesent venenatis metus at',
    },
    {
      id: _mock.id(9),
      author: {
        id: _mock.id(10),
        avatarUrl: _mock.image.avatar(randomInArray([7, 8, 9, 10, 11]) || 7),
        name: _mock.name.fullName(index + 6),
      },
      createdAt: _mock.time(3),
      message:
        'Etiam rhoncus. Nullam vel sem. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam. Sed lectus.',
    },
  ],
}));

export const _userCards = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: _mock.image.avatar(index),
  cover: _mock.image.cover(index),
  name: _mock.name.fullName(index),
  follower: randomNumberRange(999, 99999),
  following: randomNumberRange(999, 99999),
  totalPosts: randomNumberRange(999, 99999),
  role: _mock.role(index),
}));

export const _userPayment = [...Array(2)].map((_, index) => ({
  id: _mock.id(index),
  cardNumber: ['**** **** **** 1234', '**** **** **** 5678', '**** **** **** 7878'][index],
  cardType: ['master_card', 'visa', 'master_card'][index],
}));

export const _userAddressBook = [...Array(4)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  phone: _mock.phoneNumber(index),
  country: _mock.address.country(index),
  state: 'New Hampshire',
  city: 'East Sambury',
  street: '41256 Kamille Turnpike',
  zipCode: '85807',
}));

export const _userInvoices = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  createdAt: _mock.time(index),
  price: _mock.number.price(index),
}));

export const _userList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: _mock.image.avatar(index),
  name: _mock.name.fullName(index),
  fname: _mock.name.firstName(index),
  lname: _mock.name.lastName(index),
  email: _mock.email(index),
  phoneNumber: _mock.phoneNumber(index),
  address: '908 Jack Locks',
  country: _mock.address.country(index),
  state: 'Virginia',
  city: 'Rancho Cordova',
  zipCode: '85807',
  company: _mock.company(index),
  isVerified: _mock.boolean(index),
  status: randomInArray(['employee', 'unemployed', 'intern']),
  role: _mock.role(index),
}));

export const _categoryList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  name: randomInArray(['Starter', 'Appetizers', 'Main Course']),
  type: randomInArray(['food', 'drink']),
}));

export const _itemList = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  name: randomInArray(['Prawns', 'Coke', 'Fish']),
  price: randomInArray(['20.33', '47.44', '99']),
  kjs: randomInArray(['300', '453', '903']),
  type: randomInArray(['food', 'drink']),
  quantity: randomInArray(['10', '20', '11']),
}));

export const _customerList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: _mock.image.avatar(index),
  name: _mock.name.fullName(index),
  fname: _mock.name.firstName(index),
  lname: _mock.name.lastName(index),
  email: _mock.email(index),
  phoneNumber: _mock.phoneNumber(index),
  address: '908 Jack Locks',
  country: _mock.address.country(index),
  state: 'Virginia',
  city: 'Rancho Cordova',
  zipCode: '85807',
  company: _mock.company(index),
  isVerified: _mock.boolean(index),
  status: randomInArray(['active', 'hold-on', 'banned']),
  role: _mock.role(index),
}));

export const _customerComplaintList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  orderId: randomInArray(['OD67821', 'OD52342', 'OD27632']),
  barname: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  complaintId: randomInArray(['CP67821', 'CP52342', 'CP21763']),
  customername: randomInArray(['Alex Bhatti', 'John Wick', 'Fahad Roy', 'Chris Butt', 'David Jhon']),
  reason: randomInArray(['Drink taste was awful', 'I ordered a different drink altoghther', 'I didnot order these items']),
  status: randomInArray(['pending', 'in-progress', 'completed']),
  role: _mock.role(index),
}));

export const _notificationList = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  notificationId: randomInArray(['N67821', 'N22342', 'N21763']),
  message: randomInArray(['Drink taste was awful', 'I ordered a different drink altoghther', 'I didnot order these items']),
  status: randomInArray(['pending', 'read']),
  role: _mock.role(index),
}));

export const _campaignList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: _mock.image.avatar(index),
  date:  _mock.time(index),
  campaignId: randomInArray(['CP67821', 'CP52342', 'CP27632']),
  name: randomInArray(['Mid Night Deal', 'New Years Eve', 'Earn to Burn']),
  bar: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  start:  _mock.time(index),
  end:  _mock.time(index),
  fname: _mock.name.firstName(index),
  lname: _mock.name.lastName(index),
  email: _mock.email(index),
  phoneNumber: _mock.phoneNumber(index),
  address: '908 Jack Locks',
  country: _mock.address.country(index),
  state: 'Virginia',
  city: 'Rancho Cordova',
  zipCode: '85807',
  company: _mock.company(index),
  isVerified: _mock.boolean(index),
  status: randomInArray(['pending', 'completed']),
  role: _mock.role(index),
}));

export const _barComplaintList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  orderId: randomInArray(['OD67821', 'OD52342', 'OD27632']),
  barname: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  complaintId: randomInArray(['CP67821', 'CP52342', 'CP21763']),
  customername: randomInArray(['Alex Bhatti', 'John Wick', 'Fahad Roy', 'Chris Butt', 'David Jhon']),
  level: randomInArray(['Level 0', 'Level 1', 'Level 2', 'Level 3', 'Level 4']),
  reason: randomInArray(['Drink taste was awful', 'I ordered a different drink altoghther', 'I didnot order these items']),
  status: randomInArray(['pending', 'in-progress', 'completed']),
  role: _mock.role(index),
}));

export const _barLevelList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  orderId: randomInArray(['OD67821', 'OD52342', 'OD27632']),
  barname: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  complaintId: randomInArray(['CP67821', 'CP52342', 'CP21763']),
  customername: randomInArray(['Alex Bhatti', 'John Wick', 'Fahad Roy', 'Chris Butt', 'David Jhon']),
  level: randomInArray(['Level 0', 'Level 1', 'Level 2', 'Level 3', 'Level 4']),
  reason: randomInArray(['Drink taste was awful', 'I ordered a different drink altoghther', 'I didnot order these items']),
  status: randomInArray(['pending', 'in-progress', 'completed']),
  role: _mock.role(index),
}));


export const _customerOrderHistoryList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  orderId: randomInArray(['OD67821', 'OD52342', 'OD27632']),
  amount: randomInArray(['$10', '$11', '$23', '$47', '$92', '$450', '$980', '$1024']),
  barname: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  customername: randomInArray(['Baba Yaga', 'John Wick', 'Fahad Roy', 'Chris Butt']),
  status: randomInArray(['completed', 'cancelled', 'noshow']),
  type: randomInArray(['pickup', 'served']),
  role: _mock.role(index),
}));

export const _posOrderHistoryList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  orderId: randomInArray(['ORD67821', 'ORD52342', 'ORD27632', 'ORD27672', 'ORD27636', 'ORD27642']),
  amount: randomInArray(['$10.00', '$11.00', '$23.00', '$47.00', '$92.00', '$450.00', '$980.00', '$1024.00']),
  barname: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  customername: randomInArray(['Jeff Johnsons', 'Ronald Barr', 'Roger Lum', 'Susan Connor']),
  tablenumber: randomInArray(['T01', 'T02', 'T03', 'T04', 'T05', 'T06', 'T07', 'T08', 'T09', 'T10', 'T11', 'T12', 'T13', 'T14', 'T15']),
  status: randomInArray(['Draft', 'Dine In', 'Completed','Partially Refunded','Fully Refunded','Cancelled']),
  type: randomInArray(['POS', 'Customer', 'Third party']),
  role: _mock.role(index),
}));

export const _reservationList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  time: randomInArray(['05:00 PM', '06:30 PM', '08:00 PM', '09:00 PM']),
  name: randomInArray(['Jeff Johnsons', 'Ronald Barr', 'Roger Lum', 'Susan Connor']),
  table: randomInArray(['T01', 'T02', 'T03', 'T04', 'T05']),
  partySize: randomInArray(['2', '8', '4', '10']),
  area: randomInArray(['Rooftop', 'Main Hall', 'Balcony']),
  status: randomInArray(['completed','seated','pending','cancelled','noshow']),
  role: _mock.role(index),
}));

export const _tableList = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  name: randomInArray(['T01', 'T02', 'T03', 'T04', 'T05']),
  capacity: randomInArray(['2', '4', '6', '8', '10']),
  area: randomInArray(['Hall 1', 'Lobby', 'Balcony']),
  type: randomInArray(['dinein', 'reservation']),
}));

export const _areaList = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  name: randomInArray(['Hall 1', 'Lobby', 'Balcony']),
  capacity: randomInArray(['2', '4', '6', '8', '10']),
}));

export const _barOrderHistoryList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  orderId: randomInArray(['OD67821', 'OD52342', 'OD27632']),
  amount: randomInArray(['$10', '$11', '$23', '$47', '$92', '$450', '$980', '$1024']),
  barname: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  level: randomInArray(['Level 0', 'Level 1', 'Level 2', 'Level 3', 'Level 4']),
  customername: randomInArray(['Baba Yaga', 'John Wick', 'Fahad Roy', 'Chris Butt']),
  status: randomInArray(['completed', 'cancelled', 'noshow']),
  type: randomInArray(['pickup', 'served']),
  role: _mock.role(index),
}));

export const _barPromotionList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  promotionId: randomInArray(['PR67821', 'PR52342', 'PR27632']),
  amount: randomInArray(['$10', '$11', '$23', '$47', '$92', '$450', '$980', '$1024']),
  name: randomInArray(['Mid Night Deal', 'New Years Eve', 'Earn to Burn']),
  startDate:  _mock.time(index),
  endDate:  _mock.time(index),
  customername: randomInArray(['Baba Yaga', 'John Wick', 'Fahad Roy', 'Chris Butt']),
  status: randomInArray(['completed', 'active']),
  role: _mock.role(index),
}));

export const _campaignOfferAvailedList = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  date:  _mock.time(index),
  orderId: randomInArray(['OD67821', 'OD52342', 'OD27632']),
  amount: randomInArray(['$10', '$11', '$23', '$47', '$92', '$450', '$980', '$1024']),
  barname: randomInArray(['Jack n Jill', 'Bar Tap', 'Hard Rock Cafe']),
  level: randomInArray(['Level 0', 'Level 1', 'Level 2', 'Level 3', 'Level 4']),
  customername: randomInArray(['Baba Yaga', 'John Wick', 'Fahad Roy', 'Chris Butt']),
  status: randomInArray(['completed', 'cancelled', 'noshow']),
  role: _mock.role(index),
}));

export const _barList = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: _mock.image.avatar(index),
  name: randomInArray(['Jack n Jill', 'Boiler Maker House', 'The Baxter Inn', 'Maybe Sammy', 'Old Mate Place', 'Hard Rock Cafe']),
  barId: randomInArray(['BR2132', 'BR2123', 'BR2199', 'BR2156', 'BR2149']),
  state: randomInArray(['Virginia', 'Oustin', 'Sydney']),
  suburb: randomInArray(['Albert Park', 'Altona North', 'Balwyn']),
  level: randomInArray(['0', '1', '2', '3', '4']),
  fname: _mock.name.firstName(index),
  lname: _mock.name.lastName(index),
  email: _mock.email(index),
  phoneNumber: _mock.phoneNumber(index),
  address: '908 Jack Locks',
  country: _mock.address.country(index),
  city: 'Rancho Cordova',
  zipCode: '85807',
  company: _mock.company(index),
  isVerified: _mock.boolean(index),
  status: randomInArray(['active', 'banned']),
  role: _mock.role(index),
}));

export const _menuList = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: randomInArray(['https://www.allrecipes.com/thmb/Em94Nj2QvIQ5QX0sBTaJ7KBAoqc=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/4539063-2000-5949684bb2a04a8b9a429bb5a45332a4.jpg', 'https://www.allrecipes.com/thmb/KroyBwHCvPe0cZC-SlOwQ1hLjhM=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/827520-0770a4963900479c9b0d29b3c828e557.jpg', 'https://www.allrecipes.com/thmb/WNXSLHAidqlgM1yA1zE4a4ASM38=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Basic-Chicken-Salad-29508faae44b46aaa81487f27ad46998.jpg']),
  name: randomInArray(['Tossed Salad', 'Caesar Salad', 'Leafy Green Salad', 'Greek Salad', 'Fattoush']),
  description: randomInArray(['A Greek salad consists of tomatoes, cucumbers, olives, feta, and onions. Its lightly dressed with olive oil, salt, pepper, and oregano. Greek salads can be served with or without lettuce — when there is no lettuce, its sometimes called "horiatiki."']),
  isVerified: _mock.boolean(index),
  status: randomInArray([true, false]),
  role: _mock.role(index),
}));