import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_
import { _userList } from '../../_mock/arrays';
// redux

import ingredient, { getIngredient } from 'src/redux/slices/ingredient';
import { useDispatch, useSelector } from '../../redux/store';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// sections


import StockNewEditForm from 'src/sections/@dashboard/ingredient/StockNewEditForm';

// ----------------------------------------------------------------------

export default function AllStockEditPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();

  const dispatch = useDispatch();


  const { ingredient, isLoading } = useSelector((state) => state.ingredient);

  useEffect(() => {
    if (id) {

      const getToken = localStorage.getItem("accessToken");
      
      dispatch(getIngredient(id, getToken));
    }
  }, [dispatch, id]);

  return (
    <>
      <Helmet>
        <title> Item: Edit ingredient | Venue - Egora</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit ingredient"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Menu',
              href: PATH_DASHBOARD.menu.display,
            },
            { name: ingredient?.name },
          ]}
        />

        <StockNewEditForm isEdit currentItem={ingredient} />
      </Container>
    </>
  );
}
