import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  openModal: false,
  openModalCartProduct: false,
  openModalCartProductForEdit: false,
  openModalDineIn: false,
  openModalTakeAway: false,
  openModalTakeAwayCustomAmount: false,
  openModalOrder: false,
  openModalPOSList: false,
  openModalDraftList: false,
  openModalDraft: false,
  openModalDiscount: false,
  openModalClearCart: false,
  openModalTable: false,
  openModalDraftAssignOrder: false,
  openModalDraftAddOrder: false,
  openModalOrderDetail: false,
  openModalAddMicsExpense: false,
  openModalViewMicsExpense: false,
  openModalDeleteCart: false,
  openModalDeleteDraft: false,
  openModalDeleteOrder: false,
  openModalDeleteMicsExpense: false,
  openModalRefund: false,
  selectedEventId: null,
  selectedRange: null,
  levelId: null,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.isLoading = false;
      state.events = state.events.map((event) => {
        if (event.id === action.payload.id) {
          return action.payload;
        }
        return event;
      });
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const eventId = action.payload;
      state.events = state.events.filter((event) => event.id !== eventId);
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.openModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.openModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    onOpenModal(state) {
      state.openModal = true;
    },

    onOpenModalPayInvoice(state) {
      state.openModalPayInvoice = true;
    },

    onOpenModalGiveCredit(state) {
      state.openModalGiveCredit = true;
    },

    onOpenModalGiveRefund(state) {
      state.openModalGiveRefund = true;
    },

    onOpenModalCartProduct(state) {
      state.openModalCartProduct = true;
    },
    onOpenModalCartProductForEdit(state) {
      state.openModalCartProductForEdit = true;
    },

    onOpenModalDineIn(state) {
      state.openModalDineIn = true;
    },

    onOpenModalTakeAway(state) {
      state.openModalTakeAway = true;
    },

    onOpenModalTakeAwayCustomAmount(state) {
      state.openModalTakeAwayCustomAmount = true;
    },

    onOpenModalOrder(state) {
      state.openModalOrder = true;
    },

    onOpenModalPOSListPopup(state) {
      state.openModalPOSList = true;
    },

    onOpenModalDraftListPopup(state) {
      state.openModalDraftList = true;
    },

    onOpenModalDraftPopup(state) {
      state.openModalDraft = true;
    },

    onOpenModalDiscountPopup(state) {
      state.openModalDiscount = true;
    },

    onOpenModalClearCartPopup(state) {
      state.openModalClearCart = true;
    },
    onOpenModalTablePopup(state) {
      state.openModalTable = true;
    },
    onOpenModalDraftAssignPopup(state) {
      state.openModalDraftAssignOrder = true;
    },
    onOpenModalDraftAddPopup(state) {
      state.openModalDraftAddOrder = true;
    },
    onOpenModalOrderdetailPopup(state) {
      state.openModalOrderDetail = true;
    },
    onOpenModalAddMicsExpensePopup(state) {
      state.openModalAddMicsExpense = true;
    },
    onOpenModalViewMicsExpensePopup(state) {
      state.openModalViewMicsExpense = true;
    },
    onOpenModalDeleteCart(state) {
      state.openModalDeleteCart = true;
    },
    onOpenModalDeleteDraft(state) {
      state.openModalDeleteDraft = true;
    },
    onOpenModalDeleteOrder(state) {
      state.openModalDeleteOrder = true;
    },
    onOpenModalDeleteMicsExpense(state) {
      state.openModalDeleteMicsExpense = true;
    },
    onOpenModalRefund(state) {
      state.openModalRefund = true;
    },
    setLevelId(state, action) {
      state.levelId = action.payload;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModal = false;
      state.openModalPayInvoice = false;
      state.openModalGiveCredit = false;
      state.openModalGiveRefund = false;
      state.openModalCartProduct = false;
      state.openModalCartProductForEdit = false;
      state.openModalPOSList = false;
      state.openModalDraftList = false;
      state.openModalDraft = false;
      state.openModalDiscount = false;
      state.openModalOrder = false;
      state.openModalDineIn = false;
      // state.openModalTakeAway = false;
      state.openModalClearCart = false;
      state.openModalTable = false;
      state.openModalDraftAssignOrder = false;
      state.openModalDraftAddOrder = false;
      state.openModalOrderDetail = false;
      state.openModalAddMicsExpense = false;
      state.openModalViewMicsExpense = false;
      state.openModalRefund = false;
      // state.openModalDeleteCart = false;
      // state.openModalDeleteMicsExpense = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    onCloseModalForOrderDetail(state) {
      state.openModalOrderDetail = false;
    },

    onCloseModalForAddMiscExpense(state) {
      state.openModalAddMicsExpense = false;
    },

    onCloseModalCustomAmount(state) {
      state.openModalTakeAwayCustomAmount = false;
    },
    onCloseModalForCartDelete(state) {
      state.openModalDeleteCart = false;
    },
    onCloseModalForDraftDelete(state) {
      state.openModalDeleteDraft = false;
    },
    onCloseModalForOrder(state) {
      state.openModalDeleteOrder = false;
    },
    onCloseModalForMicsExpenseDelete(state) {
      state.openModalDeleteMicsExpense = false;
    },
    onCloseModalForTakeway(state) {
      state.openModalTakeAway = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onOpenModal,
  onOpenModalPayInvoice,
  onOpenModalGiveCredit,
  onOpenModalGiveRefund,
  onOpenModalCartProduct,
  onOpenModalCartProductForEdit,
  onOpenModalDineIn,
  onOpenModalTakeAway,
  onOpenModalTakeAwayCustomAmount,
  onOpenModalOrder,
  onCloseModal,
  onCloseModalForOrderDetail,
  onCloseModalForAddMiscExpense,
  onCloseModalCustomAmount,
  onOpenModalPOSListPopup,
  onOpenModalDraftListPopup,
  onOpenModalDraftPopup,
  onOpenModalDiscountPopup,
  onOpenModalClearCartPopup,
  onOpenModalTablePopup,
  onOpenModalDraftAssignPopup,
  onOpenModalDraftAddPopup,
  onOpenModalOrderdetailPopup,
  onOpenModalAddMicsExpensePopup,
  onOpenModalViewMicsExpensePopup,
  onOpenModalDeleteCart,
  onOpenModalDeleteDraft,
  onOpenModalDeleteOrder,
  onOpenModalDeleteMicsExpense,
  onCloseModalForCartDelete,
  onCloseModalForDraftDelete,
  onCloseModalForOrder,
  onCloseModalForMicsExpenseDelete,
  onCloseModalForTakeway,
  onOpenModalRefund,
  selectEvent,
  selectRange,
  setLevelId,
} = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/calendar/events");
      dispatch(slice.actions.gesetLevelIdtEventsSuccess(response.data.events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/calendar/events/new", newEvent);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, event) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/calendar/events/update", {
        eventId,
        event,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post("/api/calendar/events/delete", { eventId });
      dispatch(slice.actions.deleteEventSuccess(eventId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
