import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  choiceGroups: [],
  choiceGroup: null
};

const slice = createSlice({
  name: 'choiceGroup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getChoiceGroupsSuccess(state, action) {
      state.isLoading = false;
      state.choiceGroups = JSON.parse(JSON.stringify(action.payload));
    },

    reRenderGroupsSuccess(state, action) {
      state.choiceGroup.map((cg) => {
        if(cg.id === action.payload) {
          cg.selected = true;
        }
      });
    },

    // GET CATEGORY
    getChoiceGroupSuccess(state, action) {
      state.isLoading = false;
      state.choiceGroup = JSON.parse(JSON.stringify(action.payload));
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getChoiceGroups(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/choiceGroups?levelId=${levelId}&venueId=${venueId}&limit=100000`);
      dispatch(slice.actions.getChoiceGroupsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reRenderChoiceGroups(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.reRenderGroupsSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getChoiceGroup(choiceGroupId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/choiceGroups/${choiceGroupId}`);
      dispatch(slice.actions.getChoiceGroupSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
