import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  orderCount: {},
};

const orderCountSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getCountSuccess(state, action) {
      state.isLoading = false;
      state.orderCount = action.payload;
    },

  },
});

// Reducer
export default orderCountSlice.reducer;

export function getRealTimeOrdersCount(venueId, levelId, token) {
  return async (dispatch) => {
    dispatch(orderCountSlice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/cart/countOrdersForPOS`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            levelId: levelId,
            venueId: venueId,
            isDeleted: false
          },
        }
      );
      dispatch(orderCountSlice.actions.getCountSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error("error", error);
      dispatch(orderCountSlice.actions.hasError(error));
      throw error;
    }
  };
}
