
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
images: [],
  image: null
};

const slice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TEAMS
    getImagesSuccess(state, action) {
      state.isLoading = false;
      state.images = action.payload;
    },

    // GET TEAM
    getImageSuccess(state, action) {
      state.isLoading = false;
      state.image = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------
export function getImages(getToken , category) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/itemImages?&limit=10000`,{
        headers: {
          Authorization: `Bearer ${getToken}`,
        },
        params: { category }, 
      });
      dispatch(slice.actions.getImagesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getImage(imageId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/itemImages/${imageId}`);
      dispatch(slice.actions.getImageSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}