import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  customers: [],
  customer: null,
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
    },

    getCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    },
  },
});

export default slice.reducer;

export function getCustomers(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/venueCustomer?levelId=${levelId}&venueId=${venueId}&isDeleted=false&limit=10000000`
      );
      dispatch(slice.actions.getCustomersSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomer(customerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/venueCustomer/${customerId}`);
      dispatch(slice.actions.getCustomerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
