import { styled, alpha } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useAuthContext } from '../../../auth/useAuthContext';
import { CustomAvatar } from '../../../components/custom-avatar';
import { Switch } from '@mui/material';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function NavAccount({ isVenueOnline, handleVenueOnlineStatusChange }) {
  const { user } = useAuthContext();

  return (
    <>
      <StyledRoot>
        <Box >
          <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} />
          {(user?.venue?.configurations?.isPickUp || user?.venue?.configurations?.isDineIn) &&
            <Switch
              sx={{
                mt: 2
              }}
              size="small"
              color="primary"
              checked={isVenueOnline}
              onChange={(e) => handleVenueOnlineStatusChange(e)}
            />
          }
        </Box>
        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user?.role}
          </Typography>
          {(user?.venue?.configurations?.isPickUp || user?.venue?.configurations?.isDineIn) &&
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary', mt: 1.5 }}>
              {isVenueOnline ? "Online" : "Offline"}
            </Typography>
          }
        </Box>
      </StyledRoot>
    </>
  );
}
