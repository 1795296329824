import sum from "lodash/sum";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  venueUsers: [],
  venueUser: null,
  venueAllUser: [],
};

const slice = createSlice({
  name: "venueUser",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getVenueUsersSuccess(state, action) {
      state.isLoading = false;
      state.venueUsers = action.payload;
    },

    // GET CATEGORY
    getVenueUserSuccess(state, action) {
      state.isLoading = false;
      state.venueUser = action.payload;
    },
    getVenueAllUserSuccess(state, action) {
      state.isLoading = false;
      state.venueAllUser = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getVenueUsers(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/venueUsers?levelId=${levelId}&venueId=${venueId}&limit=${9999999999999}`
      );
      dispatch(slice.actions.getVenueUsersSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVenueUser(venueUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/venueUsers/${venueUserId}?limit=${9999999999999}`
      );
      dispatch(slice.actions.getVenueUserSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllUsersOfVenue(venueId, getToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/venueUsers/getAllVenueUsers?&limit=${9999999999999}`,
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
          params: {
            venue: venueId,
          },
        }
      );
      dispatch(slice.actions.getVenueAllUserSuccess(response.data?.results));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
